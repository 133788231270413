<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo" :to="{name:'auth-login'}">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          AgentScout
        </h2>
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="autologin"
          />
        </div>
      </b-col>
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto text-center"
        >
            <div v-if="loading">
                <b-spinner variant="primary" style="width: 4rem; height: 4rem;" class="mb-2" />
                <b-card-title class="mb-1">
                    controllo accesso in corso
                </b-card-title>
                <b-card-text class="mb-2">
                    attendere prego ...
                </b-card-text>
            </div>
            <div v-if="messagge_error">
                <b-card-text class="mb-0 pb-0">
                    Link non valido
                </b-card-text>
                <b-card-title class="mb-2 text-danger text-uppercase h2">
                    Accesso negato
                </b-card-title>
                <p class="text-center mt-2">
                    <b-link :to="{name:'auth-login'}">
                    <feather-icon icon="ChevronLeftIcon" /> Torna al Login
                    </b-link>
                </p>
            </div>
        </b-col>
      </b-col>
      
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import router from '@/router'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BSpinner, BButton, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BSpinner, 
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BAlert, 
  },
  data() {
    return {
      userEmail: '',
      loading: true,
      messagge_error: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    console.log("Token -> "+router.currentRoute.params.token)

    this.checkAutoLoad(router.currentRoute.params.token)
  },
  methods: {
    checkAutoLoad(token) {
        console.log("Start API check Token !!!!!!!!!!!!!!\n"+token)

        this.$http.get('api/auth/autologin/'+token).then(response => {
            console.log(response)

            if(response.data.statusCode===200){
                //lancia procedura di login!
                this.messagge_error = false;

                /*
                console.log("access_token -> "+response.data.reply.access_token)
                console.log("setRefreshToken -> "+response.data.reply.access_token)
                console.log("ruolo utente loggato -> "+response.data.reply.userData.role)
                */

                useJwt.setToken(response.data.reply.access_token)
                useJwt.setRefreshToken(response.data.reply.access_token)
                localStorage.setItem('userData', JSON.stringify(response.data.reply.userData))
                
                /* gestione permessi dell'utente - per la persistenza */
                this.$ability.update(response.data.reply.userData.ability)
                
                /* redirect sulla scrivania */ 
                this.$router.replace(getHomeRouteForLoggedInUser(response.data.reply.userData.role))
                .then(() => {
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Ciao ${response.data.reply.userData.name || response.data.reply.userData.username}`,
                        icon: 'UserCheckIcon',
                        variant: 'success',
                        text: `sei loggato come ${response.data.reply.userData.role}`,
                    },
                    })
                })
            } else {
                //errore
                this.loading = false
                this.messagge_error = true;
            }
        }).catch(e => {
            console.log(e);
        });
        
    },
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
